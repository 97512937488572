// Your component file
import React from "react";
import "./Courses.scss";
import Charts from "../Charts/Charts";
export default function Courses({pdfCount, docCount, users}) {
  return (
    <React.Fragment>
      <div className="courseCards">
        <div className="heading"
        > 
      <h3>Categories</h3>
        </div>
        <div className="row">
          <div className="col-md-5">
          <div className="cards">
                <div className="card">
                  <img src="/images/pdf.svg" alt="pdf" />
                  <div>
                  <h3>Pdf</h3>
                  <p><b>{pdfCount} files</b></p>
                  </div>
              </div>
                <div className="card">
                  <img src="/images/Document.svg" alt="Document" />
                  <div>
                  <h3>Documents</h3>
                  <p><b>{docCount} files</b></p>
                  </div>
              </div>
        </div>
          </div>
          <div className="col-md-7">
        <Charts
          users={users}
        />
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}
