import React, { useState, useEffect } from "react";
import Charts from "react-apexcharts";
import Dropdown from "react-bootstrap/Dropdown";
import './Charts.scss';

export default function Chart({ users }) {
  const [paidUsersCount, setPaidUsersCount] = useState(0);
  const [freeUsersCount, setFreeUsersCount] = useState(0);

  useEffect(() => {
    // Filter users with the 4 package titles for "Paid"
    const paidUsers = users.filter(user =>
      ["Standard", "Premium", "Standard Pro", "Premium Pro"].includes(user.package_title)
    );

    // Filter users with `null` or no package title for "Free"
    const freeUsers = users.filter(user => user.package_title === null);

    // Update counts
    setPaidUsersCount(paidUsers.length);
    setFreeUsersCount(freeUsers.length);
  }, [users]);

  // Hardcoded data with specific dates for "Paid" and "Free" users
  const dataPaid = [
    { date: "2024-01-01", value: paidUsersCount },
    { date: "2024-02-01", value: paidUsersCount + 2 },
    { date: "2024-03-01", value: paidUsersCount - 1 },
    { date: "2024-04-01", value: paidUsersCount + 3 },
  ];

  const dataFree = [
    { date: "2024-01-01", value: freeUsersCount },
    { date: "2024-02-01", value: freeUsersCount + 1 },
    { date: "2024-03-01", value: freeUsersCount - 2 },
    { date: "2024-04-01", value: freeUsersCount + 4 },
  ];

  const state = {
    series: [
      {
        name: "Paid",
        data: dataPaid.map(d => ({ x: new Date(d.date).getTime(), y: d.value }))
      },
      {
        name: "Free",
        data: dataFree.map(d => ({ x: new Date(d.date).getTime(), y: d.value }))
      }
    ],
    options: {
      chart: {
        type: "line",
        height: 200,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: "zoom"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      colors: ['#FF26C2', '#57C1FF'],
      markers: {
        size: 0
      },
      // fill: {
      //   type: "gradient",
      //   gradient: {
      //     shadeIntensity: 1,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 100],
      //     colorStops: [
      //       {
      //         offset: 0,
      //         color: "#08A4FF" // Start color
      //       },
      //       {
      //         offset: 100,
      //         color: "#FF26C2" // End color
      //       }
      //     ]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: 'dd MMM yyyy', // Include the year in the format
        },
        tickAmount: 6, // Number of ticks on the x-axis
        categories: [
          new Date("2024-01-01").getTime(),
          new Date("2024-02-01").getTime(),
          new Date("2024-03-01").getTime(),
          new Date("2024-04-01").getTime(),
        ], // Hardcoded date values on the x-axis
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div id="chart">
        <div className="card">
          <div className="dropdowns">
            <div className="sale">
              <h4>User's</h4>
            </div>
            <div className="d-flex">
              <div className="months">
                <p>Paid</p>
                <p>Free</p>
              </div>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="date">
                    <p>2024</p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="">2023</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Charts
            options={state.options}
            series={state.series}
            type="line"
            height={180}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
