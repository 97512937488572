import React, { useState, useEffect } from "react";
import "./sidebars.scss";
import dashboard from "../../assets/images/d1.svg";
import UserManagement from "../../assets/images/d2.svg";
import knowledgeBase from "../../assets/images/d3.svg";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

export const Sidebars = ({ toggleSidebar }) => {
  const location = useLocation();
  const [activeImage, setActiveImage] = useState(() => {
    const storedImage = localStorage.getItem("activeImage");
    return storedImage ? storedImage : dashboard;
  });

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveImage(dashboard);
    } else if (path.startsWith("/user-management")) {
      setActiveImage(UserManagement);
    } else if (path.startsWith("/knowledge-base")) {
      setActiveImage(knowledgeBase);
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem("activeImage", activeImage);
  }, [activeImage]);

  const handleImageClick = (image) => {
    setActiveImage(image);
    if (window.innerWidth <= 768) {
      toggleSidebar(); // Call the prop function to hide the sidebar
    }
  };

  return (
    <>
      <Sidebar>
        <div className="logo">
          <img src='/images/logo.svg' alt="logo" />
        </div>
        <Menu>
          {/* <MenuItem
            onClick={() => handleImageClick(dashboard)}
            component={<Link to="/" />}
            className={activeImage === dashboard ? "active-link" : ""}
          >
            <img src={dashboard} alt="icon" />
            <p>Dashboard</p>
          </MenuItem> */}
          <MenuItem
            onClick={() => handleImageClick(UserManagement)}
            component={<Link to="/user-management" />}
            className={activeImage === UserManagement ? "active-link" : ""}
          >
            <img src={UserManagement} alt="icon" />
            <p>User Management</p>
          </MenuItem>
          <MenuItem
            onClick={() => handleImageClick(knowledgeBase)}
            component={<Link to="/knowledge-base" />}
            className={activeImage === knowledgeBase ? "active-link" : ""}
          >
            <img src={knowledgeBase} alt="icon" />
            <p>Knowledge Base</p>
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};
