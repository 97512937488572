import { useEffect, useState, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./layout.scss";
import { Sidebars } from "../sidebar/Sidebars";
import Search from "../../assets/images/search.svg";
import Avatar from "../../assets/images/avatar.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Bar from "../../assets/images/bar.png";
import { FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../Context/AuthContext";
import UploadModal from "../Modal/UploadModal"; // Import the modal
import { MyContext } from '../../components/MyContext/index';
// import { MyContext } from '../../MyContext/index';

// import axios from 'axios';
import axios from "axios";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";




export const PanelLayout = () => {
  // const [openSidebar, setOpenSidebar] = useState(true);
  // const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const useCaseId = "testid"; // Hardcoded use case ID
  // const { uploadStatusData, setUploadStatusData } = useContext(MyContext);
  const { uploadStatusData, setUploadStatusData } = useContext(MyContext);

  const [openSidebar, setOpenSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const { _setAuth, filesLoadStatus, setFilesLoadStatus } = useContext(AuthContext);


  const { isAuthenticated, isLoading, getPermission, getPermissions, getClaim, getUse, user, getToken, logout } = useKindeAuth(); // Assuming isLoading is part of the hook

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const yoToken = async () => {
    console.log(getToken())
  };

  useEffect(() => {
    yoToken()
  }, []);


  const sideBarMenu = () => {
    setOpenSidebar(!openSidebar);
  };

  useEffect(() => {
    const role = getPermission('admin:power')
    console.log('ROLE', role)
    window.scrollTo(0, 0);
  }, []);


  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    setShowModal(true); // Show modal when files are selected
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select files to upload.");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }

    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/api/add_embeddings_on_pinecone?use_case_id=${useCaseId}`, formData)
      .then((response) => {
        console.log("Files uploaded successfully:", response.data);
        setShowModal(false);  // Close modal after upload
        setSelectedFiles([]); // Clear selected files
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
    // setUploadStatusData(response)
    setFilesLoadStatus(response)
  };

  const handleDelete = (index) => {
    if (Array.isArray(index)) {
      setSelectedFiles([]); // Clear all files
    } else {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    }
  };

  // const handleLogout=()=>{
  //   console.log('HANDLE LOG OUT TRIGGERED')
  //   localStorage.removeItem('jwt')
  //   navigate("/login");

  // }

  return (
    <>
      <div className="panel-wrapper flex flex-wrap column-direction">
        {/* <button onClick={async()=>{
        const response = await axios.get(
          'http://127.0.0.1:5000/api/get_users', 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("jwt")}`,
              'Content-Type': 'application/json'
            } 
          }
        );
        console.log('YO FIILE NAME RESPONSE',response)

      }}>YO BUTTON</button> */}
        <div className={`panel-sidebar ${openSidebar ? "hide" : "show"}`}>
          <Sidebars toggleSidebar={sideBarMenu} />
        </div>
        <div
          className={`panel-main-content flex column-direction ${
            openSidebar ? "hide" : "show"
          }`}
        >
          <button
            onClick={sideBarMenu}
            className={`side-menu-btn fa fa-bars ${
              openSidebar ? "hide" : "show"
            }`}
          >
            <img src={Bar} alt="bar" />
          </button>
          <header className="panel-header items-center flex content-justify-between">
            <div className="left-side">
              <div className="search_bar">
                {/* <img src={Search} alt="Search" /> */}
                {/* <input type="text" placeholder="Search files" /> */}
              </div>
              <div className="upload">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <button
                  // onClick={() => document.getElementById("file-input").click()}
                  onClick={()=> setShowModal(true)}
                >
                  <img src="/images/upload.svg" alt="upload" /> Upload Files
                </button>
              </div>
            </div>
            <div className="right-side">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="date">
                    <FaUserCircle  className="iconbg" />
                    {/* <p>Hello, John Doe </p> */}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href=""
                    onClick={() => {
                      _setAuth(false);
                      localStorage.removeItem("jwt");
                      navigate("/login");
                    }}
                  >
                    LogOut
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <img className="search" src="/images/search.svg" alt="search" /> */}
            </div>
          </header>
          <div className="panel-main-wrapper">
            {loading ? <div className="loader"></div> : <Outlet />}
          </div>
        </div>
      </div>
      {showModal && (
        <UploadModal
          show={showModal}
          onClose={() => setShowModal(false)}
          files={selectedFiles}
          onUpload={handleUpload}
          onDelete={handleDelete}
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      )}
    </>
  );
};
