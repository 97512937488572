import React, { useState, useEffect, useRef } from "react";
import "./UserManagement.scss";
import axios from "axios";

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null); // Track which user's dropdown is visible
  const [message, setMessage] = useState(""); // Message state to show success/error messages
  const dropdownRef = useRef(null); // Ref for the dropdown element

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/get_users`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUsers(response.data);
        console.log('YO USERS', response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(null);
      }
    };

    if (dropdownVisible !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleDelete = async (email) => {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (!confirmed) return;

    // Hide the dropdown immediately after confirming the delete action
    setDropdownVisible(null);

    try {
      // Send a POST request to the Flask API to delete the user by email
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },

        body: JSON.stringify({ email: email }),
      });

      const result = await response.json();

      if (response.ok) {
        setUsers(users.filter((u) => u.email !== email)); // Update UI by removing the deleted user
        setMessage(result.message);
      } else {
        setMessage(`Error: ${result.message}`);
      }

      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error deleting user:", error);
      setMessage("Error deleting the user.");
      setTimeout(() => setMessage(""), 3000);
    }
  };

  return (
    <React.Fragment>
      <div className="userManagement">
        <div className="row">
          {/* Display success/error message */}
          {message && <div className="message">{message}</div>}

          {users
            .filter(user => {
              const displayName = user.displayName ? user.displayName.toLowerCase() : '';
              const email = user.email ? user.email : '';
              return displayName !== 'admin' && email !== 'admin@example.com';
            })
            .map((user, index) => (
              <div className="col-lg-4 col-xl-3 col-md-4" key={user.uid}>
                <div className="card">
                  <div className="header">
                    <span>{user.package_title ? user.package_title : "Non-Premium"}</span> {/* Display Non-Premium or package_title */}
                    <img
                      src={`/images/dots.svg`}
                      alt="dots"
                      className="dots"
                      onClick={() => toggleDropdown(index)} // Toggle dropdown visibility
                    />
                    {dropdownVisible === index && (
                      <div className="dropdown" ref={dropdownRef}>
                        <button onClick={() => handleDelete(user.email)}>Delete</button>
                      </div>
                    )}
                  </div>
                  <div className="user">
                    <img
                      src={user.photoURL || `/images/user${(index % 5) + 1}.svg`}
                      alt="user"
                    />
                    <h5>{user.displayName || user.email.split('@')[0]}</h5> {/* Extract name from email */}
                    <p>{user.email}</p>
                  </div>
                </div>
              </div>
            ))}

        </div>
      </div>
    </React.Fragment>
  );
}

export default UserManagement;
