import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { KindeProvider, useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { MyProvider } from './components/MyContext/index';
import { AuthProvider } from './Context/AuthContext';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <AuthProvider>

    <MyProvider>

      <KindeProvider
        clientId="69f6245c282c4b73a3984d3b3d00a916"
        domain="https://massdriver.kinde.com"
        redirectUri="http://localhost:3000/"
        logoutUri="http://localhost:3000/"
      >
          <App />

      </KindeProvider>
    </MyProvider>
    </AuthProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
