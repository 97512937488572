import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, deleteUser  } from "firebase/auth";



// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCW70fMMzR93ET-LFiliYq22ofcOMRI6Uc",
    authDomain: "regal-bot-8a0cc.firebaseapp.com",
    projectId: "regal-bot-8a0cc",
    storageBucket: "regal-bot-8a0cc.appspot.com",
    messagingSenderId: "138071540718",
    appId: "1:138071540718:web:0881a16d7dab6005c584ff",
    measurementId: "G-5WQWLDQHWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();

// Function to sign up with email and password
const signUpWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing up:', error.message);
    throw error;
  }
};

// Function to sign in with email and password
const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in:', error.message);
    throw error;
  }
};

// Function to sign in with Google
const signInWithGoogle = async () => {
  try {
    const result = await auth.signInWithPopup(GoogleProvider);
    const user = result.user;
    return user;
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
    throw error;
  }
};

export { auth, GoogleProvider, signUpWithEmail, signInWithEmail, signInWithGoogle, deleteUser };
