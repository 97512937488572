import React, { useState, useEffect,useContext } from "react";
import "./Activity.scss";
import axios from "axios";
// import { MyContext } from '../../components/MyContext/index';
import {AuthContext} from '../../../../Context/AuthContext'

export default function Activity({files, message, _setAuth, users, handleDelete, getFileIcon}) {
 
  return (
    <React.Fragment>
      <div className="activity">
        <div className="heading">
          <h3>Recent activity</h3>
        </div>

        {message && <div className="message">{message}</div>}

        <div className="card">
          <table>
            <thead>
              <tr>
                <th>Recent files</th>
                <th>Upload Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>
                    <span>
                      <img src={getFileIcon(file.file_name)} alt="file icon" />{" "}
                      {file.file_name}
                    </span>
                  </td>
                  <td>{file.time_stamp}</td>
                  <td>
                    <button
                      className="deletebtn"
                      onClick={() => handleDelete(file.file_name)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
