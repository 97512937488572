// import React from "react";
// import {
//   Route,
//   HashRouter as Router,  // Change BrowserRouter to HashRouter
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ErrorPage } from "../components/ErrorPage/ErrorPage";
// import { PanelLayout } from "../components/layout/PanelLayout";
// import Dashboard from "../pages/DashboardPages/Dashboard/Dashboard";
// import UserManagement from "../pages/DashboardPages/UserManagement/UserManagement";
// import KnowledgeBase from "../pages/DashboardPages/KnowledgeBase/KnowledgeBase";
// import Login from "../components/Login/Login"


// const isAuthenticated = true;

// const PrivateRoute = ({ isAuthenticated, children }) => {
//   if (!isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }
//   return children;
// };


// export default function index() {
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           errorElement={<ErrorPage />}
//           element={
//             <PrivateRoute isAuthenticated={isAuthenticated}>
//               <PanelLayout />
//             </PrivateRoute>
//           }
//         >
//           <Route index element={<Dashboard />} />
//           <Route path="/knowledge-base" element={<KnowledgeBase />} />
//           <Route path="/user-management" element={<UserManagement />} />
//         </Route>
//       </Routes>
//     </Router>
//   );
// }








// import React, { useState, useEffect } from "react";
// import {
//   Route,
//   BrowserRouter as Router,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ErrorPage } from "../components/ErrorPage/ErrorPage";
// import { PanelLayout } from "../components/layout/PanelLayout";
// import Dashboard from "../pages/DashboardPages/Dashboard/Dashboard";
// import UserManagement from "../pages/DashboardPages/UserManagement/UserManagement";
// import KnowledgeBase from "../pages/DashboardPages/KnowledgeBase/KnowledgeBase";
// import Login from "../components/Login/Login";
// import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

// // PrivateRoute component
// const PrivateRoute = ({ isAuthenticated, isLoading, children, getPermission , getPermissions, getClaim, getUser, user, getToken}) => {
//   if (isLoading) {
//     return <div>Loading...</div>; // Show a loading indicator while authentication is being processed
//   }

//   if (!isAuthenticated) {
//     const admin_panel =  getPermission('admin_panel')
//     console.log('NOT AUHT admin_panel',admin_panel)
//     return <Navigate to="/login" replace />;
//   }
//   const admin_panel =  getPermission('admin_panel')
//   console.log('admin_panel',admin_panel)
//   console.log('getPermissions',getPermissions())
//   console.log('getClaim',getClaim('admin:power'))

//   console.log('USER ROLE',user.roles)
//   console.log('USER GUYT',user)
//   console.log('YO TOKEN',getToken())
//   console.log("getUser XXX",)


  
  
//   return children;
// };

// // Main component
// export default function  Index() {
//   const { isAuthenticated, isLoading, getPermission, getPermissions, getClaim, user, getToken,getUser } = useKindeAuth(); // Assuming isLoading is part of the hook



//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />

//         <Route
//           path="/"
//           element={
//             <PrivateRoute isAuthenticated={isAuthenticated} isLoading={isLoading} getPermission={getPermission}
//             getPermissions={getPermissions}
//             getClaim={getClaim}
//             user={user}
//             getToken={getToken}
//             getUser={getUser}
//             >
//               <PanelLayout />
//             </PrivateRoute>
//           }
//         >
//           <Route index element={<Dashboard />} />
//           <Route path="/knowledge-base" element={<KnowledgeBase />} />
//           <Route path="/user-management" element={<UserManagement />} />
//         </Route>
//       </Routes>
//     </Router>
//   );
// }





/////////












import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { PanelLayout } from "../components/layout/PanelLayout";
import Dashboard from "../pages/DashboardPages/Dashboard/Dashboard";
import UserManagement from "../pages/DashboardPages/UserManagement/UserManagement";
import KnowledgeBase from "../pages/DashboardPages/KnowledgeBase/KnowledgeBase";
import Login from "../components/Login/Login";
import ForgetPassword from "../components/ForgetPassword/ForgetPassword";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../Context/AuthContext";


const PrivateRoute = ({ _auth, children }) => {
  console.log('YOU AUTH PRIVATE ROUTE',_auth)
  
  if (!_auth) {
    return <Navigate to="/login" replace />;
  }
  return children;
};


export default function Index() {


  const { _auth,_setAuth } = useContext(AuthContext);


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          // errorElement={<ErrorPage />}
          element={
            <PrivateRoute _auth={_auth}>
              <PanelLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/user-management" element={<UserManagement />} />
        </Route>
        <Route path="/forget" element={<ForgetPassword />} />
      </Routes>
    </Router>
  );
}


















