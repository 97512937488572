import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  // Initialize _auth based on the presence of the JWT in localStorage
  const [_auth, _setAuth] = useState(!!localStorage.getItem('jwt'));
  const [filesLoadStatus,setFilesLoadStatus]=useState(null);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      _setAuth(true);
    } else {
      _setAuth(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ _auth, _setAuth, filesLoadStatus, setFilesLoadStatus }}>
      {children}
    </AuthContext.Provider>
  );
};
