import React, { useState, useEffect, useContext } from "react";
import Courses from "../KnowledgeBase/Courses/Courses";
import Activity from "../KnowledgeBase/Activity/Activity";
import axios from "axios";
import { AuthContext } from '../../../Context/AuthContext'

export default function KnowledgeBase() {
  const [files, setFiles] = useState([]);
  const [pdfCount, setPdfCount] = useState(0); // Count for PDF files
  const [docCount, setDocCount] = useState(0); // Count for DOC/DOCX files
  const [message, setMessage] = useState(""); 
  const { _setAuth, filesLoadStatus, setFilesLoadStatus } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/get_users`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUsers(response.data);
        console.log('YO USERS', response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchFileNames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/file_names`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("ACTIVITY FILES RESPONSE", response.data);
        setFiles(response.data);

        // Count files by type
        let pdfCounter = 0;
        let docCounter = 0;

        response.data.forEach(file => {
          const extension = file.file_name.split(".").pop().toLowerCase();
          if (extension === 'pdf') {
            pdfCounter += 1;
          } else if (extension === 'doc' || extension === 'docx') {
            docCounter += 1;
          }
        });

        if(response){
          setFilesLoadStatus(true)
        }

        // Update state with the counts
        setPdfCount(pdfCounter);
        setDocCount(docCounter);

      } catch (error) {
        console.error("Error fetching files", error);
      }
    };
    fetchFileNames();
  }, [filesLoadStatus]);

  const handleDelete = async (fileName) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete the file: ${fileName}?`
    );
  
    if (!confirmed) {
      return;
    }
  
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete_file/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Delete response:", response.data);
  
      // Get the file extension and convert it to lowercase
      const extension = fileName.split(".").pop().toLowerCase();
  
      // Subtract from the appropriate count based on the file extension
      if (extension === 'pdf') {
        setPdfCount(prevCount => Math.max(0, prevCount - 1));
      } else if (extension === 'doc' || extension === 'docx') {
        setDocCount(prevCount => Math.max(0, prevCount - 1));
      }
  
      // Remove the deleted file from the file list
      setFiles(files.filter((file) => file.file_name !== fileName));
  
      // Show success message
      setMessage(`File "${fileName}" has been successfully deleted.`);
  
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error("Error deleting file", error);
      setMessage(`Error deleting the file "${fileName}".`);
  
      setTimeout(() => setMessage(""), 3000);
    }
  };
  

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "/images/pdf.svg";
      case "doc":
      case "docx":
        return "/images/Document.svg";
      default:
        return "/images/t1.svg"; // Default file icon
    }
  };

  return (
    <React.Fragment>
      {/* <div>
        <p>Number of PDF files: {pdfCount}</p>
        <p>Number of DOC/DOCX files: {docCount}</p>
      </div> */}
      <Courses 
        pdfCount={pdfCount}
        docCount={docCount}
        users={users}

      />
      <Activity
        files={files}
        message={message}
        _setAuth={_setAuth}
        users={users}
        filesLoadStatus={filesLoadStatus}
        setFilesLoadStatus={setFilesLoadStatus}
        handleDelete={handleDelete}
        getFileIcon={getFileIcon}
      />
    </React.Fragment>
  );
}
