// import React, { useEffect, useState, useContext } from 'react';
// import './Login.scss';
// import { useNavigate, Link } from "react-router-dom";
// import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
// import IframeComponent from './IframeComponent';
// import { auth, GoogleProvider, signInWithEmail, signUpWithEmail} from '../../Firebase/Config';
// import { AuthContext } from '../../Context/AuthContext';


// function Login({setAuth,auth}) {
//   const navigate = useNavigate();

//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const { _auth } = useContext(AuthContext);

//   const { login, register, getToken,isAuthenticated  } = useKindeAuth();
// // 

//   return (
//     <React.Fragment>
//       <div className="login">
//         <div className="block">
//           <img className='back' src="/images/login.svg" alt="login" />
//         </div>
//         <div className="loginForm">
//           <div className="title">
//             <h3>Welcome To RegalBot</h3>
//           </div>
//           <div className="buttons">
//             <button type="button" onClick={login}>
//               Login 
//             </button>
//             <button type="button" onClick={register}>
//               Register 
//             </button>

//           </div>
        

//         </div>
        

//       </div>
//     </React.Fragment>
//   );
// }

// export default Login;












///////////////////////////////////////////////////////////////




import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Firebase/Config';
import { sendPasswordResetEmail } from 'firebase/auth'; // Import reset function
import './ForgetPassword.scss';

function ForgetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      setMessage('Failed to send reset email. Please try again.');
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <div className="ForgetPassword">
      <div className="block">
        <img className="back" src="/images/login.svg" alt="ForgetPassword" />
      </div>
      <div className="ForgetPasswordForm">
        <div className="title">
          <h3>
            <img src="/images/logo.svg" alt="logo" />
          </h3>
        </div>
        <div>
          <div className="form-group margin-top margin-top">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email to receive password reset"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Capture email input
            />
            <img src="/images/f1.svg" alt="img" />
          </div>

          <button
            className="ForgetPasswordBtn margin-top"
            onClick={handleResetPassword} // Call reset password function
          >
            Reset Password
          </button>
          <p className={message ? 'success-message' : ''}>
            {message}
          </p>
          <div className="or">
            <p>
              <span>Or </span>
            </p>
          </div>
          <button
            className="ForgetPasswordBtn"
            onClick={() => navigate('/login')}
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
