import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { Link } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";

import { auth, GoogleProvider, signInWithEmail } from "../../Firebase/Config";
import { AuthContext } from "../../Context/AuthContext";
import { IoMdCloseCircle } from "react-icons/io";
function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Added error state for validation
  const { _setAuth } = useContext(AuthContext);

  // Email format validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const SignInWithGoogle = async () => {
    try {
      setLoading(true);
      console.log("SIGN IN WITH GOOGLE");
      const result = await signInWithPopup(auth, GoogleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/firebase/auth`,
        { idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.account_type !== "admin") {
        navigate("/login");
      }

      sessionStorage.setItem("tokens", response.data.tokens);
      sessionStorage.setItem("name", response.data.name);
      sessionStorage.setItem("picture", response.data.picture);
      localStorage.setItem("jwt", response.data.token);

      _setAuth(true);
      console.log("User signed in:", user);
      console.log("Login data:", response.data);
      navigate("/");
    } catch (error) {
      console.error("Error during sign-in:", error);
      setError("Error during Google sign-in.");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async () => {
    setLoading(true);
    setError(""); // Clear previous errors

    // Email validation
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Password validation
    if (password.trim() === "") {
      setError("Password cannot be empty.");
      setLoading(false);
      return;
    }

    try {
      const userCredential = await signInWithEmail(email, password);
      const user = userCredential;
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login_admin`,
        { idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("jwt", response.data.token);
      _setAuth(true);

      navigate("/user-management");
    } catch (error) {
      console.error("Error during email sign-in:", error);

      // Handle Firebase auth errors
      switch (error.code) {
        case "auth/user-not-found":
          setError("No user found with this email.");
          break;
        case "auth/wrong-password":
          setError("Incorrect password.");
          break;
        case "auth/invalid-email":
          setError("Invalid email address.");
          break;
        case "auth/invalid-credential":
          setError("Invalid credentials. Please check your email and password.");
          break;
        default:
          setError("Error during sign-in. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="login">
        <div className="block">
          <img className="back" src="/images/login.svg" alt="login" />
        </div>
        <div className="loginForm">
          <div className="title">
            <h3>
              <img src="/images/logo.svg" alt="logo" />
            </h3>
          </div>
          <div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <img src="/images/f1.svg" alt="img" />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img src="/images/f2.svg" alt="img" />
            </div>
            <div className="forget">
              <Link to="/forget">Forgot Password?</Link>
            </div>

            {/* Render error message */}

            {error && (
              <p className="error-message">
                <span style={{ marginRight: "15px", fontSize:"25px",  }}>
                  <IoMdCloseCircle />
                </span>
                {error}
              </p>
            )}

            <button
              className={`loginBtn ${loading ? "dull-button" : ""}`}
              onClick={handleEmailSignIn}
              disabled={loading}
            >
              {loading ? "Signing in..." : "Sign in with Regal Bot"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
