// src/MyContext.js
import React, { createContext, useState } from 'react';

// Create a context
export const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState("Hello, World!");
  const [uploadStatusData, setUploadStatusData] = useState("YO MAN WAZZUPP MA MAN");
  const [loginData, setLoginData] = useState("YO MAN WAZZUPP MA MAN");


  return (
    <MyContext.Provider value={{ uploadStatusData, setUploadStatusData, loginData, setLoginData }}>
      {children}
    </MyContext.Provider>
  );
};
