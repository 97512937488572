import React, { useState, useEffect } from "react";
import "./UploadModal.scss";
import Modal from "react-bootstrap/Modal";

const UploadModal = ({ show, onClose, onUpload, onDelete, setSelectedFiles, selectedFiles }) => {
  const [isUploading, setIsUploading] = useState(false); // Track uploading state
  const [error, setError] = useState(""); // Track error messages

  useEffect(() => {
    let timer;
    if (error) {
      // Set a timer to clear the error after 10 seconds
      timer = setTimeout(() => {
        setError("");
      }, 10000); // 10 seconds

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleCancel = () => {
    setTimeout(() => {
      onClose();
      setSelectedFiles([]); // Clear all files
    }, 300);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      // Separate valid files and empty files
      const validFiles = files.filter(file => file.size > 0);
      const emptyFiles = files.filter(file => file.size === 0);

      if (validFiles.length > 0) {
        setSelectedFiles(validFiles);
      }
      
      if (emptyFiles.length > 0) {
        setError("Some of the selected files are empty and will not be uploaded.");
      } else {
        setError(""); // Clear any previous errors
      }
    }
  };

  const handleUpload = async () => {
    setIsUploading(true); // Start loader
    try {
      await onUpload(); // Call onUpload without passing individual files, let PanelLayout handle it
    } finally {
      setIsUploading(false); // Stop loader after upload is done
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click(); // Trigger hidden file input
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      // Separate valid files and empty files
      const validFiles = files.filter(file => file.size > 0);
      const emptyFiles = files.filter(file => file.size === 0);

      if (validFiles.length > 0) {
        setSelectedFiles(validFiles);
      }
      
      if (emptyFiles.length > 0) {
        setError("Some of the dropped files are empty and will not be uploaded.");
      } else {
        setError(""); // Clear any previous errors
      }
    }
  };

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      {/* Modal Header */}
      <div className="head">
        <img src="/images/uploadfiles.svg" alt="uploadfiles" />
        <div>
          <h2>Upload files</h2>
          <p>Select and upload the files of your choice</p>
        </div>
      </div>

      {/* Drag and Drop Area */}
      <div
        className="innerContent"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="inputBox" onClick={triggerFileInput}>
          <img src="/images/uploadImg.svg" alt="uploadImg" />
          <h3>Choose a file or drag & drop it here</h3>
          <p>PDF, and Doc formats, up to 50MB</p>
          <button type="button">Browse Files</button>
        </div>

        <input
          type="file"
          id="fileInput"
          multiple // Allow multiple file selection
          style={{ display: "none" }} // Hidden file input
          onChange={handleFileChange}
        />

        {/* Show Error Message */}
        {error && <div className="error">{error}</div>}

        {/* Show Selected Files */}
        {selectedFiles.length > 0 && (
          <div className="uploadedFiles">
            {selectedFiles.map((file, index) => {
              // Determine the file type based on the extension
              let fileIcon = "/images/t1.svg"; // Default icon
              const fileName = file.name.toLowerCase();
              if (fileName.endsWith(".pdf")) {
                fileIcon = "/images/pdf.svg";
              } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
                fileIcon = "/images/Document.svg";
              }

              return (
                <div key={index} className="uploadedFile">
                  <div className="file">
                    <img src={fileIcon} alt="file" />
                    <div>
                      <h5>{file.name}</h5>
                      <p className="d-flex">
                        <span>{isUploading ? "Uploading..." : "Ready to upload"}</span>
                      </p>
                    </div>
                  </div>
                  <img
                    src="/images/closeC.svg"
                    alt="remove"
                    onClick={() => removeFile(index)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Modal Footer */}
      <div className="modal-actions">
        <button onClick={handleCancel} disabled={isUploading}>
          Cancel
        </button>
        {selectedFiles.length > 0 && (
          <button onClick={handleUpload} disabled={isUploading}>
            {isUploading ? <span className="loader"></span> : "Upload"}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default UploadModal;
